import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

type LoaderProps = {
  size?: number;
  margin?: number;
  position?: "relative" | "absolute";
  align?: number | string;
};
export default function Loader(props: LoaderProps) {
  const { size, margin, align = "unset", position = "relative" } = props;

  const RootStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: position,
    top: align+"%",
    left: align+"%",
    transform: "translate(-" + align + "%, -" + align + "%)",
  }));

  return (
    <RootStyle>
      {position && (
        <CircularProgress
          size={size}
          sx={{ m: { margin } }}
        />
      )}
    </RootStyle>
  );
}
