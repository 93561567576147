// da sistemare

// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

type PageProps = {
  children: any;
  title: string;
};
export default function Page(props: PageProps) {
  const { children, title = "" } = props;
  return (
    <Box sx={{ mt: 5 }}>
      <title>{title}</title>
      {children}
    </Box>
  );
}
