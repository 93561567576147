import {
    Container,
    Button,
    TextField,
    Stack,
    Typography,
    Grid,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { PaymentService } from '../services/payment.service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';
import { PaymentAction } from '../model/Payment';
import { QrPayment } from '../model/QrPayment';
import { useEventWeb3 } from '../hooks/web3Hook';
import Loader from '../components/Loader';
import GenericModal from '../components/GenericModal';
import { GenericModalProps } from '../model/ModalInterface';
import React from 'react';
import palette from '../theme/palette';

export default function GetPaid() {
    const [amount, setAmount] = useState<number>();
    const [blockInput, setBlockInput] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [loadQR, setLoadQR] = useState(false);
    const eventWeb3 = useEventWeb3();
    const [open, setOpen] = useState(false);
    const [objModal, setObjModal] = useState<GenericModalProps>();
    const amountInput = React.useRef<HTMLInputElement>(null);

    const deleteBtnSx = {
        backgroundColor: '#E74C3C',
        '&:hover': {
            backgroundColor: '#C0392B',
        },
        boxShadow: 'none',
    };
    const listenPayment = async (id: string) => {
        try {
            await eventWeb3.subscribeToPayment(id!, handleOpen);
        } catch (error) {
            return error;
        }
    };

    const createTransaction = async (amount: number) => {
        const payload: QrPayment = {
            merchantId: '624c654b28ece2b2faac9298',
            action: PaymentAction.PAYMENT,
            cart: {
                name: 'QR CODE ITEM',
                imageUrl:
                    'https://cryptopay-store.wink.by/static/media/ethereum.cf8aa5e7.svg',
                quantity: 1,
                price: amount,
            },
        };

        try {
            const result = await PaymentService.createPayment(payload);
            setTransactionId(result._id!);
            listenPayment(result._id!);
            setLoadQR(false);
        } catch (e) {
            toast.error('An error has occurred, try again later', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    };

    const deleteTransaction = async (id: string) => {
        try {
            const response = await PaymentService.deletePayment(id);
            if (amountInput.current !== null)
                setAmount(
                    parseFloat(amountInput.current.value.replace(/,/g, '.'))
                );
            setTransactionId(null);
            setBlockInput(false);
            // console.log(response);
        } catch (e) {
            console.error(e);
        }
    };

    const handleOnClick = () => {
        if (
            amount === null ||
            amount! <= 0 ||
            amountInput.current?.value === ''
        )
            return;
        createTransaction(amount!);
        setLoadQR(true);
        setBlockInput(true);
    };

    const handleOnChange = e => {
        const newAmount = parseFloat(e.target.value.replace(/,/g, '.'));
        if (newAmount !== amount) {
            setAmount(newAmount);
        }
    };

    const handleDeletePayment = () => {
        deleteTransaction(transactionId!);
    };

    function handleClose() {
        setOpen(false);
    }

    function handleOpen(obj) {
        setObjModal(obj);
        setOpen(true);
    }
    return (
        <Page title="get_paid">
            {open && objModal && (
                <GenericModal
                    path={objModal.path}
                    handleClose={handleClose}
                    open={open}
                    message={objModal.message}
                    title={objModal.title}
                    textBtn={objModal.textBtn}
                    functionBtn={objModal.functionBtn}
                    color={objModal.color}
                />
            )}
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item md={6} sx={{ width: 1 }} order={{ xs: 1 }}>
                        <Stack spacing={3} sx={{ m: 5 }}>
                            <div>
                                <Typography variant="h3">Get paid</Typography>
                                {blockInput ? (
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: 14,
                                        }}>
                                        You have to cancel the current payment
                                        in order to generate a new one.
                                    </Typography>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                color: 'gray',
                                                fontSize: 14,
                                            }}>
                                            A QR code for your payment will be
                                            generated according to the amount
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: palette.warning.darker,
                                                fontSize: 14,
                                            }}>
                                            Warning: Once your client has
                                            accepted the payment on Metamask,
                                            canceling it won't take effect.
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <TextField
                                type="number"
                                inputRef={amountInput}
                                disabled={blockInput}
                                variant="outlined"
                                label="Amount (€)"
                                onChange={handleOnChange}
                            />
                            {transactionId ? (
                                <Button
                                    sx={deleteBtnSx}
                                    onClick={handleDeletePayment}
                                    variant="contained"
                                    size="large">
                                    Cancel Payment
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleOnClick}
                                    variant="contained"
                                    disabled={blockInput}
                                    size="large">
                                    Generate QR code
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                    <Grid
                        order={{ xs: 2 }}
                        item
                        md={6}
                        sx={{ p: 2 }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid
                            justifyContent="center"
                            alignItems="center"
                            position="relative">
                            {transactionId && !loadQR && (
                                <QRCodeSVG
                                    value={`https://metamask.app.link/dapp/cryptopay-gateway-demo.wink.by/qrpayment/?ref=${transactionId}`}
                                    size={256}
                                />
                            )}
                            {loadQR && (
                                <Loader
                                    size={50}
                                    margin={0}
                                    position="absolute"
                                    align={50}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <ToastContainer />
            </Container>
        </Page>
    );
}
