import { EventService } from "../context/eventProvider";
import { useContext, createContext } from "react";

export const Web3EventInstance = new EventService();
export const Web3Event = createContext<EventService>(Web3EventInstance);

export const useEventWeb3 = (): EventService => {
    const context = useContext(Web3Event);
    if (context === undefined) {
      throw new Error("useWeb3() hook must be used within a provider");
    }
    return context;
}

