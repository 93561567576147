import { Box, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

const DRAWER_WIDTH = 280;

const FooterStyle = styled('div')(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: 0,
  left: 0,
  padding: "15px",
  background: "white",
  width: "100%",
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    left: `${DRAWER_WIDTH + 1}px`
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    left: `${DRAWER_WIDTH + 1}px`
  }
}));

export default function Footer(){
  return(
    <Box>
      <FooterStyle>
        <Typography variant="h6" sx={{
            fontStyle: "italic", 
            color: "text.secondary",
            cursor: "default"
          }}>
          Powered by <Link
            href="https://wink.by/"
            target="_blank"
            color="#df4929"
            underline="none"
            >WiNK</Link>
        </Typography>
      </FooterStyle>
    </Box>
  )
}