import { ReactNode, ReactPortal, useState } from "react"
import { CurrencyContext } from ".";

type CurrencyProviderProps = {
    children?: ReactNode | ReactPortal;
};

const CurrencyProvider = (props: CurrencyProviderProps) => {
    const [currency, setCurrency] = useState<Map<string, number | undefined>>();

    return (<CurrencyContext.Provider value={{currency, setCurrency}}>
        {props?.children}
    </CurrencyContext.Provider>);
}

export default CurrencyProvider;