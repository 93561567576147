import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BalanceContext, CurrencyContext, UserContext } from '../../../context';
import Loader from '../../../components/Loader';
import { WalletItem } from '../../../model/WalletItem';
import { MerchantService } from '../../../services/merchant.service';
import { ConversionService } from '../../../services/convert.service';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 0),
    color: theme.palette.primary.darker,
    backgroundColor: theme.palette.primary.lighter,
    minHeight: '100%',
}));

export default function CurrentBalance() {
    const [load, setLoad] = useState(true);
    const [wallet, setWallet] = useState<WalletItem[]>();
    const { accBalance, setAccBalance } = useContext(BalanceContext);
    const { currency } = useContext(CurrencyContext);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const calculateBalance = () => {
            let sum = 0;
            let tmp: number | undefined;
            wallet &&
                wallet.forEach(item => {
                    currency && (tmp = currency?.get(item.currency));
                    tmp && (sum += tmp * item.amount);
                });
            setAccBalance(ConversionService.formatPrice(sum));
        };
        currency && calculateBalance();
    }, [currency, setAccBalance, wallet]);

    useEffect(() => {
        const fetchMerchant = async () => {
            if (user) {
                try {
                    const result = await MerchantService.getMerchant(user);
                    setWallet(result.wallet);
                    setLoad(false);
                } catch (e) {
                    console.error(e);
                }
            }
        };
        fetchMerchant();
    }, [user]);

    return (
        <RootStyle>
            <Typography variant="h3">Current Balance</Typography>
            {!load ? (
                <Typography variant="h4">{accBalance} €</Typography>
            ) : (
                <Loader size={25} margin={5} />
            )}
        </RootStyle>
    );
}
