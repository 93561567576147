import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Payment } from '../../../model/Payment';
import { CurrencyContext, UserContext } from '../../../context';
import { PaymentService } from '../../../services/payment.service';
import { ConversionService } from '../../../services/convert.service';
import Loader from '../../../components/Loader';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 1),
    color: theme.palette.warning.darker,
    backgroundColor: theme.palette.warning.lighter,
    minHeight: '100%',
}));

export default function MountlyIncomes() {
    const [load, setLoad] = useState(true);
    const [balance, setBalance] = useState<string>('0');
    const { user } = useContext(UserContext);
    const { currency } = useContext(CurrencyContext);

    useEffect(() => {
        const calculateBalance = (props: Payment[]) => {
            const mDate = new Date();
            mDate.setDate(mDate.getDate() - 30);
            let sum = 0;
            let tmp: number | undefined;
            props.forEach(item => {
                if (new Date(item.date) >= mDate) {
                    currency && (tmp = currency?.get(item.currency));
                    tmp && (sum += tmp * item.finalAmount);
                }
            });
            setBalance(ConversionService.formatPrice(sum).toString());
        };

        const fetchPayments = async () => {
            if (user) {
                try {
                    const result = await PaymentService.getClosedPayments(user);
                    calculateBalance(result);
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoad(false);
                }
            }
        };
        if (user && currency) {
            fetchPayments();
        }
    }, [user, currency]);

    return (
        <RootStyle>
            <Typography variant="h3">Incomes in the last 30 days </Typography>
            {!load ? (
                <Typography variant="h4">{balance}€</Typography>
            ) : (
                <Loader size={25} margin={5} />
            )}
        </RootStyle>
    );
}
