import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import Image from '../assets/logo.svg'

// ----------------------------------------------------------------------

type LogoProps = {
  sx? : object;
}

export default function Logo(props : LogoProps) {
  const {sx} = props;
  return (
    <RouterLink to="/">
      <Box component="img" src={Image} alt="Logo" sx={{ width: 80, height: 80, ...sx }} />
    </RouterLink>
  );
}
