import {
  FormControl,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useContext } from "react";
import NotImplementDialog from "../../../components/NotImplementDialog";
import { BalanceContext } from "../../../context";

export default function WireTransfer() {
  const { accBalance } = useContext(BalanceContext);

  return (
    <FormControl sx={{ m: 5, minWidth: "40%" }}>
      <Stack spacing={3}>
        <Typography variant="h3">Wire Transfer</Typography>
        <TextField variant="outlined" label="IBAN" />
        <Stack direction="row" spacing={2}>
          <TextField variant="outlined" label="Amount" />
          <Typography
            variant="body1"
            sx={{
              py: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            Available Amount: {accBalance} €
          </Typography>
        </Stack>
        <FormControlLabel
          control={<Checkbox />}
          label="convert automatically all my coins to fit the wire transfer"
        />
        <NotImplementDialog text="Request Wire Transfer" />

        {/* <Button variant="contained" size="large">Request Wire Transfer</Button> */}
      </Stack>
    </FormControl>
  );
}
