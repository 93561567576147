// component

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: 'Homepage',
    path: '/dashboard/app',
    act: true,
  },
  {
    title: 'Withdraw/Deposit',
    path: '/dashboard/withdraw_deposit',
    act: true,
  },
  {
    title: 'Convert',
    path: '/dashboard/convert',
    act: false,
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    act: false,
  },
  {
    title: 'Get paid',
    path: '/dashboard/get_paid',
    act: true,
  },
];

export default sidebarConfig;
