import { createContext } from 'react';

type UserContextType = {
    user?: string;
    setUser: (user: string) => void;
};

export const UserContext = createContext<UserContextType>(
    {} as UserContextType
);

type CurrencyContextType = {
    currency?: Map<string, number | undefined>;
    setCurrency: (currency: Map<string, number | undefined>) => void;
};

export const CurrencyContext = createContext<CurrencyContextType>(
    {} as CurrencyContextType
);

type BalanceContextType = {
    accBalance?: string;
    setAccBalance: (accBalance: string) => void;
};

export const BalanceContext = createContext<BalanceContextType>(
    {} as BalanceContextType
);
