import { Currency } from "../enum/Currency";
import { Chain } from "../model/Chain";

export const supportedChains: Chain[] = [
  {
    currency: Currency.ETH,
    name: "Ethereum",
    coingeckoId: "ethereum"
  },
  {
    currency: Currency.MATIC,
    name: "Polygon",
    coingeckoId: "matic-network"
  },
];
