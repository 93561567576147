import { useContext, useEffect, useState } from 'react';
import { CurrencyContext, UserContext } from '../../../context';
import {
    Box,
    Button,
    Card,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { WalletItem } from '../../../model/WalletItem';
import { ConversionService } from '../../../services/convert.service';
import { MerchantService } from '../../../services/merchant.service';
import Loader from '../../../components/Loader';
import NotImplementDialogInner from '../../../components/NotImplementDialogInner';

type NewRowProps = {
    item: WalletItem;
};

function NewItem(props: NewRowProps) {
    const { item } = props;
    const { currency } = useContext(CurrencyContext);

    const getConv = () => {
        let tmp: number | undefined;
        currency && (tmp = currency?.get(item.currency));
        tmp && (tmp *= item.amount);
        if (tmp) return ConversionService.formatPrice(tmp).toString() + '€';
        else return <Loader size={20} />;
    };

    return (
        <TableRow>
            <TableCell>{item.currency}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.amount.toString().substring(0, 8)}</TableCell>
            <TableCell sx={{ minWidth: '100px' }}>{getConv()}</TableCell>
        </TableRow>
    );
}

export default function Wallet() {
    const [wallet, setWallet] = useState<WalletItem[]>([]);
    const [load, setLoad] = useState(true);
    const [open, setOpen] = useState<boolean>(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchMerchant = async () => {
            if (user) {
                try {
                    const result = await MerchantService.getMerchant(user);
                    setWallet(result.wallet);
                } catch (e) {
                    console.log(e);
                }
                setLoad(false);
            }
        };
        fetchMerchant();
    }, [user]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card>
            {!load ? (
                <TableContainer>
                    <Table sx={{ width: '90%', mx: 'auto', mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Abbreviation</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wallet ? (
                                wallet.map(item => (
                                    <NewItem key={item.currency} item={item} />
                                ))
                            ) : (
                                <Typography variant="h6" sx={{ my: 2 }}>
                                    Your Wallet is empty.
                                </Typography>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Loader size={40} margin={20} />
            )}
            <Divider />
            <Box sx={{ p: 2, textAlign: 'right' }}>
                <Button
                    size="small"
                    color="inherit"
                    onClick={handleOpen}
                    endIcon={<KeyboardArrowUpIcon />}>
                    View all
                </Button>
                <NotImplementDialogInner
                    open={open}
                    handleClose={handleClose}
                />
            </Box>
        </Card>
    );
}
