import { Container, Grid } from "@mui/material";
import Page from "../components/Page";
import CurrentBalance from "../sections/dashboard/app/CurrentBalance";
import MountlyIncomes from "../sections/dashboard/app/MountlyIncomes";
import Wallet from "../sections/dashboard/app/Wallet";
import WeeklyEarning from "../sections/dashboard/app/WeeklyEarning";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="CryptoPay">
      <Container maxWidth="xl">
        {/* 3 container di colore diverso contenenti dati */}
        <Grid container spacing={3} alignItems="stretch" justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <CurrentBalance />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <WeeklyEarning />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <MountlyIncomes />
          </Grid>
          {/* Portafoglio */}
          <Grid item xs={12} md={12} lg={12}>
            <Wallet />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
