import axios from "axios";
import { BASE_URL } from "../environment/environment";
import { supportedChains } from "../mock/SupportedChain";
import { Chain } from "../model/Chain";
import { Login } from "../model/Login";
import { Merchant } from "../model/Merchant";
import { ConversionService } from "./convert.service";

export const MerchantService = {
  async login(payload: Login): Promise<Merchant> {
    const user: Merchant = await (
      await axios.post(`${BASE_URL}/auth/login`, payload)
    ).data.payload.user;
    return user;
  },
  getMerchant: async function (id: string): Promise<Merchant> {
    return await (
      await axios.get(`${BASE_URL}/merchant/${id}`)
    ).data.payload;
  },
  getCurrency: async function (id: string): Promise<Map<string, number | undefined>> {
    let ret = new Map<string, number>();
    const appWallet = (await this.getMerchant(id)).wallet;
    await Promise.all(
      appWallet.map(async (i) => {
        const coin : Chain | undefined = supportedChains.find((elem) => elem.currency === i.currency);
        coin && (
        await ConversionService.convert("EUR", coin.coingeckoId, 1).then((result) => {
          ret.set(i.currency, result);
        }));
      })
    );
    return ret;
  },
};
