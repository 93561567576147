import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
  minHeight:'100%'

}));

export default function WeeklyEarning() {
  const BALANCE = "75,4"
  const APY = "1"

  return (
    <RootStyle>
      <Typography variant="h3"
      >
        Weekly Earnings
      </Typography>
      <Typography variant="h4">{BALANCE} CRP</Typography>
      <Typography variant="h4">APY {APY} %</Typography>
    </RootStyle>
  );
}
