import { Container, Divider } from "@mui/material";
import Page from "../components/Page";
import CryptoWithdrawal from "../sections/dashboard/withdrawDeposit/CryptoWithdrawal";
import WireTransfer from "../sections/dashboard/withdrawDeposit/WireTransfer";

export default function Withdrawal() {
    return (
      <Page title="Withdrawal">
        <Container maxWidth="xl">
            <WireTransfer />
            <Divider />
            <CryptoWithdrawal />
        </Container>
      </Page>
    );
  }
  